<template>
  <div class="popup_wrap" style="width:800px;">
    <button type="button" class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont" >
      <h1 class="page_title">{{ $t('msg.ONEX060P011.001') }}<!-- B/L 수정 확인 --></h1>
      <div class="content_box">
        <table class="tbl_col">
          <colgroup>
            <col width="50%">
            <col width="50%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.ONEX060P011.002') }}<!-- B/L 수정 시 주의사항 --></th>
              <th>{{ $t('msg.ONEX060P011.003') }}<!-- 수정 요청시(Correction Advice) --></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text_left valign_top">
                <div class="p10" v-html="$t('msg.ONEX060P011.004')">
                  <!-- B/L 수정 시 주의사항 - 설명 -->
                </div>
              </td>
              <td class="text_left valign_top">
                <div class="p10" v-html="$t('msg.ONEX060P011.005')">
                  <!-- 수정 요청시(Correction Advice) - 설명 -->
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <p v-if="memberDetail.userCtrCd === 'JP'" class="mt10">
          {{ $t('msg.ONEX060P011.009') }}
        </p>
        <p class="mt10">
          <input type="checkbox" id="agreea1" name="agree" v-model="agreeYn" true-value="Y" false-value="N">
          <label for="agreea1" class="color_black" v-html="$t('msg.ONEX060P011.006')">
            <!-- B/L 수정 시 주의사항을 충분히 인지하고 수정 요청서를 고려해운으로 발송함. -->
          </label>
        </p>
      </div>
      <div class="text_center mt10">
        <a href="#" class="button blue lg" @click.prevent="goModify()">{{ $t('msg.ONEX060P011.007') }}<!-- 확인 --></a>
        <a href="#" class="button gray lg" @click.prevent="$emit('close')">{{ $t('msg.ONEX060P011.008') }}<!-- 취소 --></a>
      </div>
    </div>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'

export default {
  name: 'BLModifyNotiPop',
  props: {
    parentInfo: {
      type: Object,
      default () {
        return {
          page: '',
          params: {}
        }
      }
    }
  },
  data () {
    return {
      agreeYn: 'N',
      lang: ''
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    this.lang = this.auth.serviceLang
  },
  methods: {
    goModify () {
      // console.log(this.$props.parentInfo)

      if (this.agreeYn !== 'Y') {
        this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('art.CMATK335') }) // 약관에 동의 하여야 합니다.
        return
      }

      this.$emit('callback', this.$props.parentInfo)
    }
  }
}
</script>
